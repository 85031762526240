<!-- 合格证台账打印功能-->
<template>
    <div style="margin:0 auto;margin-top:1px;width:1300px;height:920px">
        <table  style="width: 100%;word-wrap: break-word; word-break: break-all;">
            <tr>
                <td width="25%">

                </td>
                <td width="50%" style="text-align: center;">
                    <h2>合格证管理台账</h2>
                </td>
                <td width="25%">
                    {{modelcfg.companycode}}-GL-107-01
                </td>
            </tr>
        </table>
        <table id="tbl" v-if="cerrecorddata!=null" border="1px solid black" cellspacing="0"  style="margin:0 auto;width:100%;height:90%"  >
            <tr style="font-size:16px;font-weight:bold">
                <th align="left">序号</th>
                <th align="center" valign="center">申请日期</th>
                <th align="center" valign="center">产品型号</th>
                <th align="center" valign="center">整车编码</th>
                <th align="center" valign="center">电机编码</th>
                <th align="center" valign="center">合格证编码</th>
                <th align="center" valign="center">生产日期</th>
                <th align="center" valign="center">颜色</th>
                <th align="center" valign="center">打印时间</th>
                <th align="center" valign="center">备注</th>
            </tr>
            <tr v-for="(item,index) in cerrecorddata" >
                <td>{{index}}</td>
                <td>{{getpreDate(item.madedate)}}</td>
                <td>{{item.modelno}}</td>
                <td>{{item.vehicleno}}</td>
                <td>{{item.motorno}}</td>
                <td>{{item.certificateno}}</td>
                <td>{{formatDate(item.madedate)}}</td>
                <td>{{item.vehiclecolor}}</td>
                <td>{{formatDate(item.printdate)}}</td>
                <td>{{item.note}}</td>
            </tr>
        </table>
        <table  style="width: 100%;margin-top: 8px;word-wrap: break-word; word-break: break-all;">
            <tr style="text-align: left;">
                <td width="33%">
                    申请人:
                </td>
                <td width="33%" >
                    打印人:
                </td>
                <td width="33%">
                    质检部长:
                </td>
            </tr>
        </table>
    </div>
</template>
 <script>
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'CerRecordPrint',
        props:['cerrecorddata'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg
            }
        },
        methods:{
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            }
        },
        mounted(){
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}

#tbl {
    
}

#tbl  th {
    font-size: 16px;
    font-weight: bold;
}
#tbl  td{
    font-size: 12px;
    font-family: "楷体";
    padding-left: 4px;
}
</style>