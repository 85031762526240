<template>
    <div >

        <!-- 文件列表-->
        <div style="margin: 20px auto;float: right;width: 714.7pt;">
            <Button type="success"  @click="handleQuery">刷新数据</Button>
            <Button type="warning"  @click="previewdata" style="margin-left: 15px;">打印预览</Button>
            <Button type="primary"  @click="printdata" style="margin-left: 15px;">直接打印</Button>
        </div>
        <div id="printdiv" style="padding-top:10px;clear: both;">
            <p style="text-align: center;"><span style="font-size: 24pt;">认证标志台账</span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{modelcfg.companycode}}-CX-006-03</p>
            <p style="text-align: left;">&nbsp;</p>
            <p style="text-align: left;">标志入库记录表</p>
            <table class="MsoTableGrid" style="width: 714.7pt; border: none;" border="1" cellspacing="0">
                <tbody>
                    <tr>
                    <td style="width: 812.625px; padding: 0pt 5.4pt; border: 1pt solid windowtext;" colspan="9" valign="top" width="1191">
                    <p class="MsoNormal" style="text-align: center;" align="center">&nbsp;</p>
                    </td>
                    </tr>
                    <tr style="height: 21.8500pt;">
                        <td style="width: 58.625px; padding: 0pt 5.4pt; border-left: 1pt solid windowtext; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" rowspan="2" valign="center" width="88">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">1&nbsp;</span></p>
                        </td>
                        <td style="width: 77.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;标志规格</span></p>
                        </td>
                        <td style="width: 84.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 94.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="138">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">购买日期&nbsp;</span></p>
                        </td>
                        <td style="width: 169.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="245">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 86.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-size: 12pt;"><span style="font-family: 宋体;">&nbsp;</span><span style="font-family: 宋体;">购买数量<span style="white-space: pre;"> </span></span></span></p>
                        </td>
                        <td style="width: 113.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="171">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 87.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;"><span style="font-family: sans-serif;">发票编号</span>&nbsp;</span></p>
                        </td>
                        <td style="width: 39.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="59">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr style="height: 21.6000pt;">
                        <td style="width: 77.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">购买地点&nbsp;</span></p>
                        </td>
                        <td style="width: 84.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 94.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="138">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">入库日期&nbsp;</span></p>
                        </td>
                        <td style="width: 169.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="245">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 86.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;入库数量</span></p>
                        </td>
                        <td style="width: 113.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="171">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 87.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;"><span style="font-family: sans-serif;">入库登记人</span>&nbsp;</span></p>
                        </td>
                        <td style="width: 39.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="59">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                        </td>
                        </tr>
                        <tr style="height: 21.6000pt;">
                        <td style="width: 812.625px; padding: 0pt 5.4pt; border-left: 1pt solid windowtext; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" colspan="9" valign="center" width="1191">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;标志出库记录</span></p>
                        </td>
                    </tr>
                    <tr style="height: 21.6000pt;">
                        <td style="width: 58.625px; padding: 0pt 5.4pt; border-left: 1pt solid windowtext; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="88">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;序号</span></p>
                        </td>
                        <td style="width: 77.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;标志数量</span></p>
                        </td>
                        <td style="width: 84.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;领用数量</span></p>
                        </td>
                        <td style="width: 94.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="138">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">剩余数量</span></p>
                        </td>
                        <td style="width: 169.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="245">
                        <p class="MsoNormal" style="text-indent: 31.5000pt; mso-char-indent-count: 3.0000; text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">领用人/日期&nbsp;</span></p>
                        </td>
                        <td style="width: 86.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">&nbsp;产品型号</span></p>
                        </td>
                        <td style="width: 113.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="171">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 12pt;">标志破损/遗失登记&nbsp;</span></p>
                        </td>
                        <td style="width: 87.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;标志回收记录</span></p>
                        </td>
                        <td style="width: 59.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: 1pt solid windowtext; border-bottom: 1pt solid windowtext;" valign="center" width="79">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;备注</span></p>
                        </td>
                    </tr>
                    <tr style="height: 21.6000pt;" v-for="(item,index) in summarydata">
                        <td style="width: 58.625px; padding: 0pt 5.4pt; border-left: 1pt solid windowtext; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="88">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">{{index+1}}</span></p>
                        </td>
                        <td style="width: 77.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 84.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">{{item.mcount}}</span></p>
                        </td>
                        <td style="width: 94.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="138">
                            <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                            </td>
                        <td style="width: 169.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="245">
                        <p class="MsoNormal" style="text-indent: 31.5000pt; mso-char-indent-count: 3.0000; text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">{{modelcfg.productionmanager}}/{{item.myear}}-{{item.mmonth}}</span></p>
                        </td>
                        <td style="width: 86.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="115">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">{{item.modelno}}</span></p>
                        </td>
                        <td style="width: 113.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="171">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 87.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="128">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                        </td>
                        <td style="width: 59.625px; padding: 0pt 5.4pt; border-left: none; border-right: 1pt solid windowtext; border-top: none; border-bottom: 1pt solid windowtext;" valign="center" width="79">
                        <p class="MsoNormal" style="text-align: center;" align="center"><span style="font-family: 宋体; font-size: 10.5pt;">&nbsp;</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import * as sysTool from '@/zsjs/systemTool.js'
import CerRecordPrint from '@/views/business/workshop/checkdept/special/CerRecordPrint'
import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
export default {
    name:'ziliao',
    data () {
            return {
                modellist:this._self.$root.$store.state.ModelListInfo,
                companyid: this._self.$root.$store.state.LogonInfo.companyid,
                modelcfg:this._self.$root.$store.state.ModelCfg,
                summarydata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                let postdata = {
                    companyid: this.companyid
                }
                
                this.$axios({
                    method:'post',
                    url:"/api/checkdept/rzbztz/get",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.summarydata = res.data.data
                        console.log(this.summarydata)
                        
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            view(index){
                let openurl = window.location.protocol+"//"+window.location.host+this.tbldata[index].filepath
                window.open(openurl, '_blank');
            },
            previewdata(){
                public_preview_with_dir("printdiv",1,2);
            },
            printdata(){
                public_print_with_dir("printdiv",1,2);
            },
            
            getmodelnobyid(id){
                for(let i=0;i<this.modellist.length;i++){
                    if(this.modellist[i].id == id){
                        return this.modellist[i].modelno
                    }
                }
                return null
            },
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getstartdate(){
                return new Date().getFullYear()+'-01-01'
            }
        },
        components:{
            printview: CerRecordPrint
        }
}
</script>

<style scoped>
    .tblitemtitle {
        cursor: pointer;
    }
</style>